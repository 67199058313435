@import "./scss/constants.module.scss";

html,
body {
  padding: 0 !important;
  margin: 0;
  // font-family: "Poppins-Regular" !important;
  background-color: $bg-color !important;
  color: $text-color !important;
  overflow-x: hidden;
  main {
    padding-top: 84px !important;
  }
}
.CalendarMonthGrid_month__horizontal {
  display: none; /* Hide the start date controls */
}

a {
  color: $color-link;
  text-decoration: none;
}
a:hover {
  color: $color-hover-link;
  text-decoration: underline;
}
p.icon {
  margin: 0;
}
* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
fieldset {
  border: none;
  border-color: transparent !important;
  background-color: rgba(0, 0, 0, 0.06);
}
.DateRangePicker {
  display: inline-block;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 700000;
  background: white;
  border: 0.01em solid lightgray;
}

.loader-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid $primary;
  border-right: 6px solid $primary;
  border-bottom: 6px solid $primary;
  border-top: 6px solid $secondary;
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

canvas {
  max-width: 100% !important;
}

td {
  a {
    color: $color-link;
  }
  a:hover {
    color: $color-hover-link;
    text-decoration: underline;
  }
}

[role="tabpanel"] {
  .MuiBox-root {
    padding-left: 0;
    // padding-top: 10px;
  }
}

.flex90Form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    width: 93%;
    &.ResourceDialog {
      width: 7%;
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
    }
    &:last-of-type {
      width: 100%;
    }
  }
  &.first-100 {
    & > div:first-of-type {
      width: 100%;
    }
  }
  &.second-70 {
    & > div:nth-of-type(2) {
      width: 75%;
    }
  }
  &.nth-last-child-6 {
    & > div:nth-last-of-type(6) {
      width: 100%;
    }
  }
  &.last-100 {
    & > div:last-of-type {
      width: 100% !important;
    }
  }
  &.nth-3 {
    & > div:nth-of-type(3) {
      width: 100% !important;
    }
  }
  &.nth-4 {
    & > div:nth-of-type(4) {
      width: 93% !important;
    }
  }
  &.nth-5 {
    & > div:nth-of-type(5) {
      width: 100% !important;
    }
  }
  &.nth-6 {
    & > div:nth-of-type(6) {
      width: 100% !important;
    }
  }
  &.nth-7 {
    & > div:nth-of-type(7) {
      width: 100% !important;
    }
  }
  &.nth-8 {
    & > div:nth-of-type(8) {
      width: 100% !important;
    }
  }
  &.nth-9 {
    & > div:nth-of-type(9) {
      width: 100% !important;
    }
  }
}

.flexMachines {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    width: 93%;
    &.ResourceDialog {
      width: 7%;
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      height: 32px;
    }

    &:last-of-type {
      width: 100%;
    }
  }
  &.first-100 {
    & > div:first-of-type {
      width: 100%;
    }
  }
  &.nth-last-child-6 {
    & > div:nth-last-of-type(6) {
      width: 100%;
    }
  }
  &.nth-6 {
    & > div:nth-of-type(6) {
      width: 93% !important;
    }
  }
  &.nth-7 {
    & > div:nth-of-type(7) {
      width: 7% !important;
    }
  }
  &.nth-8 {
    & > div:nth-of-type(8) {
      width: 100% !important;
    }
  }
  &.nth-9 {
    & > div:nth-of-type(12) {
      width: 100% !important;
    }
  }
}

.locationCreateForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    width: 48%;
    &:last-of-type {
      width: 100%;
    }
  }
  &.nth-3 {
    & > div:nth-of-type(3) {
      width: 93% !important;
    }
  }
  &.nth-4 {
    & > div:nth-of-type(4) {
      width: 6% !important;
    }
  }
  &.nth-5 {
    & > div:nth-of-type(5) {
      width: 100% !important;
    }
  }
  &.nth-6 {
    & > div:nth-of-type(6) {
      width: 100% !important;
    }
  }
  &.nth-7 {
    & > div:nth-of-type(7) {
      width: 100% !important;
    }
  }
}

.edit-form__price.adjustment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    width: 48%;
    &:last-of-type {
      width: 100%;
    }
  }
}

.flexHalfForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: "none";
  & > div {
    width: 48%;
    &:last-of-type {
      width: 100%;
    }
  }
  &.first-100 {
    & > div:first-of-type {
      width: 100%;
    }
  }
  &.second-100 {
    & > div:nth-of-type(2) {
      width: 100%;
    }
  }
  &.nth-last-child-6 {
    & > div:nth-last-of-type(6) {
      width: 100%;
    }
  }
  &.last-100 {
    & > div:last-of-type {
      width: 100% !important;
    }
  }
  &.nth-3 {
    & > div:nth-of-type(3) {
      width: 100% !important;
    }
  }
  &.nth-4 {
    & > div:nth-of-type(4) {
      width: 100% !important;
    }
  }
  &.nth-5 {
    & > div:nth-of-type(5) {
      width: 100% !important;
    }
  }
  &.nth-6 {
    & > div:nth-of-type(6) {
      width: 100% !important;
    }
  }
  &.nth-16 {
    & > div:nth-of-type(6) {
      width: 38% !important;
    }
  }
  &.nth-7 {
    & > div:nth-of-type(7) {
      width: 100% !important;
    }
  }
  &.nth-8 {
    & > div:nth-of-type(8) {
      width: 100% !important;
    }
  }
  &.nth-9 {
    & > div:nth-of-type(9) {
      width: 100% !important;
    }
  }
}

.promotions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    width: 48%;
    &:last-of-type {
      width: 100%;
    }
  }
}

.paymentForm {
  & > div:last-of-type {
    width: 100%;
    margin-right: 0px !important;
    display: flex;
    justify-content: space-between;
  }
}

// @media (max-width: 1700px) {
//   .flex90Form > div:nth-of-type(2) {
//     width: 100% !important;
//   }
// }
@media (max-width: 1527px) {
  .edit-form {
    &__qr {
      .MuiFormControl-root {
        &:nth-of-type(2) {
          width: 100% !important;
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .edit-form {
    &__machine {
      .MuiFormControl-root {
        &:nth-of-type(7) {
          width: 100% !important;
          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 981px) {
  .flexHalfForm {
    & > div {
      width: 49%;
      &:last-of-type {
        width: 100%;
      }
    }
  }
}
@media (max-width: 768px) {
  .edit-form {
    &__user,
    &__machine,
    &__company,
    &__location,
    &__qr {
      & > div {
        width: 100% !important;
      }
    }
  }
  .flex90Form {
    & > div {
      width: 100%;
    }
  }
  .locationCreateForm {
    & > div {
      width: 100%;
    }
  }
  .flexHalfForm {
    & > div {
      width: 100%;
      &:last-of-type {
        width: 100%;
      }
    }
  }
}

.iconButton {
  padding: 0;
  top: -4px;
  color: $bgTextShadow;
  border-radius: 50%;
  justify-content: center;
  p {
    font-size: 1.4em;
    line-height: 1;
    text-shadow: $textShadow;
    color: $grey;
    text-shadow: 0px 2px 2px rgb(0 0 0 / 12%);
  }
}

.iconButton:hover {
  color: $color-link;
}

.backButton:hover {
  color: $color-link;
}

[class^="MuiFormControl"] {
  & + [class^="MuiBox"] {
    [class^="MuiBox"] {
      padding-top: 0;
      .iconButton {
        justify-content: start;
      }
    }
  }
}

/*======================
    404 page
=======================*/
.four_zero_four_bg {
  display: flex;
  justify-content: center;
  background-image: url("../../public/404.gif");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

MuiFormControl-root-MuiTextField-root.MuiFormControl-root {
  margin-top: 24px !important;
}
.react-tel-input {
  margin-right: 20px !important;
  .form-control {
    width: 100% !important;
    margin-right: 20px !important;
    margin-left: 0 !important;
    border: none !important;
    background-color: #f0ecec !important;
  }
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.edit-form {
  &__machine,
  &__pos {
    .MuiFormControl-root {
      &:nth-of-type(5) {
        width: 93%;
        display: inline-block;
      }
    }
    .ResourceDialog {
      width: 6%;
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  &__machine {
    .MuiFormControl-root {
      &:nth-of-type(4) {
        width: 93% !important;
      }
      &:nth-of-type(6) {
        width: 93% !important;
      }
      &:nth-of-type(7) {
        & > div {
          width: 100%;
        }
      }
    }
  }
  &__pos {
    .MuiFormControl-root {
      &:nth-of-type(7) {
        width: 93%;
        display: inline-block;
      }
    }
  }
  &__company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      width: 48%;
    }
    & > div:last-of-type {
      width: 100% !important;
    }
  }
  &__product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      width: 48%;
    }
    & > div:last-of-type {
      width: 100% !important;
    }
  }
  &__location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      width: 48%;
      &:nth-of-type(5) {
        width: 100%;
      }
    }
    & > div:last-of-type {
      width: 100% !important;
    }
    .MuiFormControl-root {
      &:nth-of-type(3) {
        width: 92%;
        display: inline-block;
      }
      & + .MuiBox-root {
        width: 6%;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        height: 32px;
        // margin-top: 5px;
      }
    }
  }
  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      width: 48%;
    }
    & > div:last-of-type {
      width: 100% !important;
    }
    // .MuiFormControl-root {
    //   &:nth-of-type(6) {
    //     // width: 92%;
    //     // display: inline-block;
    //     width: 100%;
    //   }
    //   & + .MuiBox-root {
    //     &:nth-of-type(7) {
    //       width: 6%;
    //       display: inline-flex;
    //       align-items: flex-end;
    //       justify-content: center;
    //       height: 32px;
    //       // margin-top: 5px;
    //     }
    //   }
    // }
  }
  &__qr {
    .MuiFormControl-root {
      &:nth-of-type(2) {
        width: 72%;
        display: inline-block;
      }
      &:nth-of-type(3),
      &:nth-of-type(6) {
        width: 93%;
        display: inline-block;
      }
      & + .MuiBox-root {
        width: 6%;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        height: 32px;
        margin-top: 6px;
      }
    }
  }
}

.MuiButtonBase-root-MuiTableSortLabel-root {
  display: block;
}

.css-rorn0c-MuiTableContainer-root {
  overflow-x: unset !important;
}

.MuiBox-root .css-14wfnfu {
  overflow-x: auto;
}

.css-1idn90j-MuiGrid-root {
  max-width: unset !important;
}

.react-datepicker__day--outside-month {
  color: grey;
}

.react-datepicker-popper {
  z-index: 2 !important;
  transform: unset !important;
  top: 40px !important;
}

.showRowDescription {
  & dl {
    display: flex;
    & dt {
      font-weight: bold;
    }
  }
}
[role="tabpanel"] {
  & > .MuiBox-root {
    padding-top: 0;
  }
}

// SCROLL BAR for CHROME

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #30343433;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #30343433;
}
[role="tabpanel"] {
  & > .MuiBox-root {
    padding-top: 0;
  }
}

@font-face {
  font-family: "averato";
  src: url("../../public/font/averato.eot");
  src:
    url("../../public/font/averato.eot") format("embedded-opentype"),
    url("../../public/font/averato.woff2") format("woff2"),
    url("../../public/font/averato.woff") format("woff"),
    url("../../public/font/averato.ttf") format("truetype"),
    url("../../public/font/averato.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  src: url("../../public/font/averato.eot");
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'averato';
      src: url('../font/averato.svg?696806#averato') format('svg');
    }
  }
  */
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "averato";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-company:before {
  content: "\e800";
} /* 'î €' */
.icon-dashboard:before {
  content: "\e801";
} /* 'î ' */
.icon-invoices:before {
  content: "\e802";
} /* 'î ‚' */
.icon-locations:before {
  content: "\e804";
} /* 'î ƒ' */
.icon-machine:before {
  content: "\e803";
} /* 'î „' */
.icon-module:before {
  content: "\e805";
} /* 'î …' */
.icon-order:before {
  content: "\e806";
} /* 'î †' */
.icon-promotions:before {
  content: "\e807";
} /* 'î ‡' */
.icon-qrpayment:before {
  content: "\e808";
} /* 'î ˆ' */
.icon-transaction:before {
  content: "\e809";
} /* 'î ‰' */
.icon-user:before {
  content: "\e80a";
} /* 'î Š' */
.icon-virtualpos:before {
  content: "\e80b";
} /* 'î ‹' */
.icon-wallet:before {
  content: "\e80c";
} /* 'î Œ' */
.icon-export:before {
  content: "\e80d";
}
.icon-add:before {
  content: "\e80e";
}
.icon-declined:before {
  content: "\e810";
  color: tomato;
}
.icon-approved:before {
  content: "\e811";
  color: #4caf50;
}
.icon-waiting:before {
  content: "\e812";
  color: #f5b716;
  font-size: 1.4em;
  margin-left: 0;
}

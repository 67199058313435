$primary-color: #64ff00;
$primary: #6a6afe;

$secondary: #ffffff;
$text-color: #313838;
$text-white: #ffffff;
$color-link: #067193;
$color-hover-link: blue;

$border-light: #e7effb;

$bg-grey: #d9d9d9;
$bg-red: #ad3e3e;
$grey: #9e9e9e;
$bg-color: #f5f5f5;
$bg-nav: #18294b;
$bg-dialog: rgba(255, 255, 255, 0.95);

$dark: "#000";
$error: "#FF6347";

$bg-medium: rgba(0, 0, 0, 0.15);
$bg-light: rgba(0, 0, 0, 0.06);
$bg: rgba(0, 0, 0, 0.25);
// font-size
$small: 12px;

//fontFamilies
$semiBold: "Montserrat-SemiBold";
$bold: "Montserrat-Bold";
$text-medium: "Montserrat-Medium";
$text-regular: "Montserrat-Regular";
$text-light: "Montserrat-Light";

$box-shadow-dark: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
$boxShadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$boxShadowLight: 0px 2px 2px rgba(0, 0, 0, 0.12);

$textShadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
$bgTextShadow: #4b535a;
:export {
  primary: $primary;
  secondary: $secondary;
  textColor: $text-color;
  textWhite: $text-white;
  colorLink: $color-link;
  bg: $bg;
  grey: $grey;
  error: $error;
  bgGrey: $bg-grey;
  bgRed: $bg-red;
  bgMedium: $bg-medium;
  bgLight: $bg-light;
  bgColor: $bg-color;
  bgNav: $bg-nav;
  bgDialog: $bg-dialog;
  semiBold: $semiBold;
  textMedium: $text-medium;
  textRegular: $text-regular;
  bold: $bold;
  textLight: $text-light;
  borderLight: $border-light;
  boxShadow: $boxShadow;
  boxShadowLight: $boxShadowLight;
  textShadow: $textShadow;
  bgTextShadow: $bgTextShadow;
}

@import "styles/scss/constants.module.scss";

.container {
  max-width: 100%;
  padding-bottom: 55px;
  position: relative;
  .toolbar {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  .title {
    color: $primary;
  }
  .row {
    cursor: pointer;
  }
}

@import "styles/scss/constants.module.scss";

.navigationButton {
  color: #fff;
  cursor: pointer;
  .averatoIcon {
    color: #fff;
    transition: 0.3s;
    margin-right: 15px;
    font-size: 1.7em;
    margin-left: 5px;
  }
  &__active {
    background: $bg-medium;
    box-shadow: $box-shadow-dark;
    .averatoIcon {
      margin-right: 15px;
      margin-left: 5px;
      font-size: 1.7em;
      color: $primary;
      transition: 0.3s;
    }
  }
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("../../public/font/Montserrat/Montserrat-BlackItalic.woff2")
    format("woff2");
  // src:url('../../public/font/Montserrat/Montserrat-BlackItalic.woff2')
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Black";
  src: url("../../public/font/Montserrat/Montserrat-Black.woff2")
    format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../public/font/Montserrat/Montserrat-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: url("../../public/font/Montserrat/Montserrat-ExtraLightItalic.woff2")
    format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("../../public/font/Montserrat/Montserrat-ExtraBold.woff2")
    format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("../../public/font/Montserrat/Montserrat-ExtraLight.woff2")
    format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url("../../public/font/Montserrat/Montserrat-Italic.woff2")
    format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("../../public/font/Montserrat/Montserrat-ExtraBoldItalic.woff2")
    format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../public/font/Montserrat/Montserrat-Medium.woff2")
    format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("../../public/font/Montserrat/Montserrat-BoldItalic.woff2")
    format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-LightItalic";
  src: url("../../public/font/Montserrat/Montserrat-LightItalic.woff2")
    format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("../../public/font/Montserrat/Montserrat-Light.woff2")
    format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Thin";
  src: url("../../public/font/Montserrat/Montserrat-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../public/font/Montserrat/Montserrat-Regular.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url("../../public/font/Montserrat/Montserrat-MediumItalic.woff2")
    format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url("../../public/font/Montserrat/Montserrat-ThinItalic.woff2")
    format("woff2");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url("../../public/font/Montserrat/Montserrat-SemiBoldItalic.woff2")
    format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../public/font/Montserrat/Montserrat-SemiBold.woff2")
    format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
